import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.css'],
})
export class AlertPopupComponent implements OnInit {
  title: string;
  body: any;
  okButtonLabel: string;

  constructor(
    public dialogRef: MatDialogRef<AlertPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.body = this.sanitizer.bypassSecurityTrustHtml(this.data.body);
    this.okButtonLabel = this.data.okButtonLabel || 'Ok';
  }

  handleOkClicked() {
    this.dialogRef.close(true);
  }
}
