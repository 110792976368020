<app-spinner></app-spinner>

<mat-toolbar>
    <a routerLink="./" title="Intellect Portal" style="height: 40px; margin: 20px 0px" *ngIf="isDesk">
        <img alt="Intellect Portal" src="../../../../assets/images/360intellect-logo.jpg" style="max-height: 100%;">
    </a>

    <a routerLink="./" title="Intellect Portal" style="height: 40px;" *ngIf="!isDesk">
        <img alt="Intellect Portal" src="../../../../assets/images/logo-icon.png" style="max-height: 100%;">
    </a>

    <span class="header-links">
        <a [routerLink]="['/', routerPath, 'dashboard']">
            Dashboard
        </a>

        <a (click)="navigateToConfig()" title="Intellect Configuration">
            Configure  <i class="fa fa-cog" aria-hidden="true"></i>
        </a>
        <img src="../../../../assets/images/salesforce.png" routerLink="/adminDashboard/sf" class="admin-link" title="Intellect Portal" style="height: 22px;"/>
    </span>

    <span class="example-spacer"></span>

    <i class="fa fa-refresh" aria-hidden="true" (click)="refreshComponent()"></i>
    <span><i class="fa fa-user" aria-hidden="true"></i>{{userData.userName}}</span>

    <button mat-icon-button (click)="toggleSidebar()" class="sidebar-toggle-btn" *ngIf="!isDesk">
        <mat-icon>menu</mat-icon>
    </button>
</mat-toolbar>

<mat-sidenav-container>
    <mat-sidenav #sidenav="matSidenav" [ngClass]="{'collapse' : !isExpanded}">
        <div class="expansion-panel-header collapse-menu" (click)="toggleIsExpanded()">
            <span *ngIf="isExpanded"><i class="menu-icon fa fa-angle-double-left" aria-hidden="true"></i></span>
            <span [ngStyle]="{margin: 'auto'}" *ngIf="!isExpanded"><i class="fa fa-angle-double-right"
                    aria-hidden="true"></i></span>
            <span *ngIf="isExpanded">Menu</span>
        </div>
        <div *ngFor="let menu of menuList; let i = index" class="menu">
            <div *ngIf="menu.expandable" class="expansion-panel" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" [ngClass]="{'expanded': expandedMenu.includes(i)}">
                <div class="expansion-panel-header" (click)="menuClick(i)">
                    <span><i class="menu-icon" [ngClass]="menu.iconClass" aria-hidden="true"></i></span>
                    <span *ngIf="isExpanded">{{menu.title}}</span>
                    <div *ngIf="isExpanded" class="example-spacer"></div>
                    <mat-icon *ngIf="isExpanded" class="open">add</mat-icon>
                    <mat-icon *ngIf="isExpanded" class="close">remove</mat-icon>
                </div>
                <div class="expansion-panel-body">
                    <mat-list>
                        <mat-list-item *ngFor="let submenu of menu.subMenus" [routerLink]="submenu.path"
                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            (click)="subMenuClick(i, submenu)">
                            <a class="submenu" *ngIf="isExpanded">{{submenu.title}}</a>
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>

            <div *ngIf="!menu.expandable" class="expansion-panel non-expandable" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" [ngClass]="{active: menu.path == route}"
                [routerLink]="menu.path" (click)="subMenuClick(i, menu)">
                <div class="expansion-panel-header">
                    <span><i class="menu-icon" [ngClass]="menu.iconClass" aria-hidden="true"></i></span>
                    <span *ngIf="isExpanded">{{menu.title}}</span>
                </div>
            </div>
        </div>
        <div class="menu">
            <div class="expansion-panel non-expandable" (click)="logout()">
                <div class="expansion-panel-header">
                    <span><i class="menu-icon fa fa-sign-out" aria-hidden="true"></i></span>
                    <span *ngIf="isExpanded">Logout</span>
                </div>
            </div>
        </div>
        <!-- <div class="copyright"> @{{year}} {{copyrightText()}} </div> -->
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>